<template>
	<div class="box">
		<div class="tab" ref="tab">
			<img src="../assets/images/logo.png" style="width: 56px; height: 56px; border-radius: 20%" />
			<!-- <div style="" class="flex1">
				<div v-for="(item, index) in tabs" :key="index" style="margin: 0 58px 0 0; font-weight: 600; cursor: pointer">
					<div :class="{ active: active === index }" @click="switchTab(index, item)">
						{{ item }}
					</div>
				</div>
			</div> -->
            <img src="../assets/images/sav.png" style="width: 27px; height: 27px" @click="drawer = true" />
		</div>
		<div class="cont" ref="cont" style="">
			<div ref="cont_1" style="text-align:center">
				<div class=" shouye" style="padding:44px 24px 0">
					<div style="margin-right: 0px;text-align:left;flex:1">
						<div class="text1">AI规划+专家咨询+平台辅导</div>
						<div class="text2" style="margin-top: 16px">助力青年人</div>
						<div class="text2">求职及职业发展</div>
					</div>
					<div style="display: flex;justify-content:flex-end">
                        <img src="../assets/images/shouye.png" style="width: 172px; height:131px;margin:-100px 0 0 0;" />
                    </div>
				</div>
				<img src="../assets/images/shouye1.png" style="width: 342px; height:171px;margin-top: 42px" />
			</div>

			<div class="" ref="cont_2">
				<div style="text-align: center; font-weight: 600; margin: 43px 0 32px 0; font-size: 28px; color: rgba(0, 0, 0, 0.88)">产品服务</div>
				 <div class="" style="padding:0px 24px 0">
					<div style="margin-right: 37px; color: rgba(0, 0, 0, 0.88);">
						<div style="font-size: 14px;font-weight: 600;margin-left:4px">
							央国企、互联网、高科技、<br />
							绿色环保、大健康
						</div>
						<div style="margin: 8px 0 8px 0" class="flex1">
							<div style="color: #ff662c; font-size: 24px">1000</div>
							<div style="color: #ff662c; margin:0 4px 10px 0; font-size: 16px">+</div>
							<div style="font-size: 18px; color: #333;font-weight: 600">各行业职场专家</div>
						</div>
						<div style="background: #f5f5f5; border-radius: 10px; padding: 12px; font-size: 11px;width:184px">
							<div>资深职场专家1V1指导</div>
							<div style="margin: 8px 0">平均面试成功率提高80%</div>
							<div>职业推荐、自我介绍、薪资谈判</div>
						</div>
					</div>
                    <div style="display: flex;justify-content:flex-end">
                        <img src="../assets/images/chanpin.png" style="width: 227px; height:238px;margin:-90px 0 0 0;" />
                    </div>
                    <div class="flex">
                        <img src="../assets/images/weixin.png" style="width:141px; height: 48px; margin-top: 8px" />
                    </div>
				</div>
				<div class="" style="margin-top:64px;padding:0px 24px 0">
                    <div style="display: flex;align-items: flex-end;flex-direction: column; color: rgba(0, 0, 0, 1)">
						<div style="font-size: 20px;margin: 0 12px 12px 0;font-weight:600">AI简历</div>
						<div style="font-size: 14px; width:163px">
                            根据岗位需求快速生成专属简历使你的简历更出彩
                        </div>
					</div>
					<img src="../assets/images/ai.png" style="width: 227px; height: 238px" />
                    <div class="flex">
                        <img src="../assets/images/weixin.png" style="width:141px; height: 48px; margin-top: 8px" />
                    </div>
				</div>
			</div>

			<div ref="cont_3" style="">
				<div style="text-align: center; font-weight: 600; margin: 64px 0 52px 0; font-size: 28px; color: rgba(0, 0, 0, 0.88)">专家服务</div>
				<div class="" style="padding:0px 24px 0">
					<div style="font-weight: 600; font-size: 20px; color: rgba(0, 0, 0, 0.88)">职涯罗盘</div>
					<div style="font-size: 14px; color: rgba(0, 0, 0, 0.88); line-height: 22px;padding:10px 0 24px;letter-spacing:1px;">
						"职涯罗盘"是一款专为应届大学生量身定制的职涯规划服务。<br />
						帮助每位毕业生都渴望在职场上找到自己的定位和发展方向。
					</div>
                    <div style="text-align: center;">
                        <img src="../assets/images/luopan.png" style="width: 283px; height: 363px" />
					    <img src="../assets/images/weixin.png" style="width: 141px; height: 48px" />
                    </div>
				</div>

				<div class="" style="">
					<div style=" border-radius: 46px;padding:0 46px;margin: 64px 0 0 0;">
						<div style="font-weight: 600; font-size: 20px; color: rgba(0, 0, 0, 0.88)">简历舵手</div>
						<div style="font-size: 14px; color: rgba(0, 0, 0, 0.88); line-height: 22px; margin: 10px 0 0 0">
							'简历舵手"是一款专为应届大学生设计的简历优化服务。<br />
							我们的专家团队将根据每位求职者的行业偏好、目标企业和理想岗位，量身定制简历，确保每一份简历都能精准地传达求职者的专业技能和个人特质。
						</div>
                        <img src="../assets/images/duoshou.png" style="width:283px; height: 389px; z-index: 999; margin-top:32px" />
						<div class="flex">
                            <img src="../assets/images/weixin.png" style="width: 141px; height:48px" />
                        </div>
					</div>
				</div>

				<div class="" style="">
					<div style=" border-radius: 46px;padding:0 46px;margin: 64px 0 0 0;">
						<div style="font-weight: 600; font-size: 20px; color: rgba(0, 0, 0, 0.88)">灯塔之光</div>
						<div style="font-size: 14px; color: rgba(0, 0, 0, 0.88); line-height: 22px;margin: 10px 0 0 0">
							"灯塔之光"是一款专为应届大学生设计的求职面试辅导服务。<br />
							"灯塔之光"服务通过邀请目标公司、对应岗位的在职专家，为求职者提供精准、实用的面试模拟和问题回答指导。
						</div>
                        <img src="../assets/images/dengta.png" style="width: 283px; height:431px; margin-top:32px" />
						<div class="flex">
                            <img src="../assets/images/weixin.png" style="width: 141px; height:48px" />
                        </div>
					</div>
				</div>
			</div>

			<div class="cont_4" ref="cont_4" style="">
				<div style="height:64px"></div>
				<div style="font-size: 32px; color: #000; text-align: center; font-weight: 600">关于我们</div>
				<div style="height: 24px"></div>
				<div class="about">
					<div style="font-weight: 600;">
						<div class="textdi">邮箱：1504452775@qq.com</div>
						<div class="textdi" style="margin:11px 0">商务/联系方式：17316127135</div>
						<div class="textdi">客服联系方式：dengtazhiya001</div>
					</div>
					<img src="../assets/images/beian.png" style="width: 210px; height: 160px;margin-left:-100px" />
				</div>
				<div style="background: #89afd0; height: 98px; font-size: 14px; text-align: center; color: #fff; line-height: 98px">备案号:京ICP备2023021456号-11</div>
			</div>
		</div>


        <el-drawer
            style="height: 220%;"
            :with-header="false"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose">
            <div class="tab1" ref="tab">
                <img src="../assets/images/logo.png" style="width: 56px; height: 56px; border-radius: 20%" />

                <img src="../assets/images/close.png" style="width: 27px; height: 27px" @click="drawer = false" />
            </div>
            <div style="font-size: 24px;color:#000" class="">
				<div v-for="(item, index) in tabs" :key="index" style=" cursor: pointer;text-align: center; margin: 35px 0">
					<div :class="{ active: active === index }" @click="switchTab(index, item)">
						{{ item }}
					</div>
				</div>
			</div>
        </el-drawer>
	</div>
</template>
<script>
export default {
	data() {
		return {
			tabs: ['首页', '产品服务', '专家服务', '关于我们'],
			active: 0,
			cont1: null,
			cont2: null,
			cont3: null,
			cont4: null,
			isClickTab: false,
            drawer: false,
            direction:'ttb',
		}
	},
	methods: {
        handleClose(done) {
            // console.log(done,'-->>>')
            // this.$confirm('确认关闭？').then(_ => {
                done();
            // })
            // .catch(_ => {});
        },
		switchTab(index) {
			console.log(index,'index')
            this.drawer = false
            this.active = index
			// 根据当前index切换到对应的页面内容
			if (index === 0) {
				this.cont1.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
			} else if (index === 1) {
				this.cont2.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
			} else if (index === 2) {
				this.cont3.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
			} else if (index === 3) {
				this.cont4.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
				// console.log('--->',this.cont4)
			}
		},
	},
	mounted() {
		this.cont1 = this.$refs['cont_1']
		this.cont2 = this.$refs['cont_2']
		this.cont3 = this.$refs['cont_3']
		this.cont4 = this.$refs['cont_4']

		const tabH = this.$refs['tab'].offsetHeight

		// console.log(tabH,'sssssss')
		// 添加scroll事件监听
		this.$refs['cont'].addEventListener('scroll', () => {
			// console.log('[]][]')
			// console.log(this.cont4.getBoundingClientRect().top,tabH,'this.cont4')
			// console.log(this.cont3.getBoundingClientRect().top,tabH,'this.cont3')
			// console.log(this.cont2.getBoundingClientRect().top,tabH,'this.cont2')
			// console.log(this.cont1.getBoundingClientRect().top,tabH,'this.cont1')
			// if (this.cont4.getBoundingClientRect().top <= tabH) {
			// 	this.active = 3
			// 	return false
			// }
			// if (this.cont3.getBoundingClientRect().top <= tabH) {
			// 	this.active = 2
			// 	return false
			// }
			// if (this.cont2.getBoundingClientRect().top <= tabH) {
			// 	this.active = 1
			// 	return false
			// }
			// if (this.cont1.getBoundingClientRect().top <= tabH) {
			// 	this.active = 0
			// }
		})
	},
}
</script>
<style  scoped>
.flex1{
    display: flex;
    align-items: center;
    
}
.home {
	text-align: center;
}
.flex1 {
	display: flex;
	align-items: center;
}
.flex2 {
	display: flex;
	justify-content: center;
}
.flex {
	display: flex;
	align-items: center;
	justify-content: center;
}
.shouye {
	background: linear-gradient(180deg, #e2f1ff 0%, #ffffff 100%);
}
.text1 {
	font-weight: 600;
	font-size: 16px;
	color: #ff662c;
	line-height: 22px;
}
.text2 {
	font-weight: 600;
	font-size: 20px;
	color: rgba(0, 0, 0, 0.88);
	line-height:28px;
}
.box {
	font-size: 28px;
	/* overflow-x: auto; */
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
.tab {
    padding: 15px 32px 15px 18px;
	height: 86px;
	background: rgb(245, 242, 255);
	line-height: 88px;
	color: #666;
	display: -webkit-flex;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
}
.tab1{
    padding: 15px 32px 15px 18px;
	height: 86px;
	background: rgb(255, 255, 255);
	line-height: 88px;
	color: #666;
	display: -webkit-flex;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
}
.active {
	font-size:24px;
	color: #4e85ff;
}
/* .active::after {
	display: block;
	content: '';
	width: 30px;
	height: 6px;
	margin: auto;
	margin-top: -30px;
	background: #4E85FF;
	border-radius: 3px;
} */
.cont {
	/* height: 300px; */
	flex-grow: 1;
	overflow: auto;
}
.about {
	background: #eef7ff;
	height: 192px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
    padding: 0 0 0 30px;
}
.textdi {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.88);
	/* line-height: 22px; */
}
</style>
