<template>
	<div id="app">
		<!-- <shop-header v-if="show"></shop-header> -->
		<!-- <div class="container"></div> -->
		<router-view />
		<!-- <div v-if="pageWidth > 768">
			<router-view to="/" />
		</div> -->
		<!-- <div>
			<router-view to="/mobile" ></router-view>
		</div> -->
		<!-- {{pageWidth}} -->
		<!-- <shop-footer v-if="show"></shop-footer> -->
	</div>
</template>
<script>
// import ShopHeader from '@/layouts/Header/'
// import ShopFooter from '@/layouts/Footer'
export default {
	components: {
		// ShopHeader,
		// ShopFooter,
	},
	data() {
		return {
			// 是否显示头部和底部展示
			show: true,
			pageWidth: window.innerWidth
		} 
	},
	created() {
		window.addEventListener('resize', this.handleResize);
		localStorage.setItem('pageWidth',this.pageWidth)
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		handleResize() {
			this.pageWidth = window.innerWidth;
			
		}
	},
	watch: {
		$route: {
			handler: function (val, oldVal) {
				console.log(val.name,'22')
				
				// if (val.name == 'login') {
				// 	this.show = false
				// } else {
				// 	this.$store.dispatch('getCarNum')
				// 	this.show = true
				// }
			},
			// 深度观察监听
			deep: true,
			immediate: true // 监听到路由的变化立即执行
		}
	},
}
</script>

<style lang="less">
@import 'assets/theme/index.less';

.container {
	width: @container-width;
	margin: 0 auto;
}
</style>
