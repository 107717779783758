<template>
	<div class="box">
		<div class="tab" ref="tab">
      <img src="../assets/images/logo.png" style="width: 45px; height: 45px;border-radius: 20%" /> 
			<div style="" class="flex1">
        <div v-for="(item, index) in tabs" :key="index" style="margin: 0 58px 0 0;font-weight: 600;cursor:pointer">
          <div :class="{ active: active === index }" @click="switchTab(index,item)">
            {{ item }}
          </div>
        </div>
      </div>
		</div>
		<div class="cont" ref="cont" style="">
			<div ref="cont_1" style="text-align:center">
        <div class="flex shouye">
					<div style="margin-right: 73px">
						<div class="text1">AI规划+专家咨询+平台辅导</div>
						<div class="text2" style="margin-top: 16px">助力青年人</div>
						<div class="text2">求职及职业发展</div>
					</div>
					<img src="../assets/images/shouye.png" style="width: 432px; height: 327px" /> 
				</div>
        <img src="../assets/images/shouye1.png" style="width: 960px; height: 486px; margin-top: 132px" />
      </div>


			<div class="" ref="cont_2">
        <div style="text-align:center;font-weight: 600;margin:97px 0 52px 0;font-size: 48px;color: rgba(0,0,0,0.88);">产品服务</div>
        <div class="flex">
          <div style="margin-right: 37px;color: rgba(0,0,0,0.88)">
            <div style="font-size:22px">
              央国企、互联网、高科技、<br> 绿色环保、大健康
            </div>
            <div style="margin:10px 0 2px 0" class="flex1">
              <div style="color: #FF662C;font-size:42px">1000</div>
              <div style="color: #FF662C;margin-bottom:20px;font-size:44px">+</div>
              <div style="font-size: 36px;color:#333">各行业职场专家</div>
            </div>
            <div style="background: #F5F5F5;border-radius: 24px;padding:24px;font-size: 19px;">
              <div>资深职场专家1V1指导</div>
              <div style="margin:16px 0">平均面试成功率提高80%</div>
              <div>职业推荐、自我介绍、薪资谈判</div>
            </div>
            <img src="../assets/images/weixin.png" style="width: 282px; height: 97px;margin-top:82px" />
          </div>
          <img src="../assets/images/chanpin.png" style="width: 550px; height: 576px" />
        </div>
        <div  class="flex2" style="margin-top:58px">
          <img src="../assets/images/ai.png" style="width:550px; height: 576px" /> 
          <div style="text-align:right;margin-left:-110px">
            <div style="font-size: 48px;color: rgba(0,0,0,0.88);margin-bottom:32px">AI简历</div>
            <div style="font-size: 28px;color: rgba(0,0,0,0.88);">
              根据岗位需求快速生成专属简历更你的简历更
            </div>
            <img src="../assets/images/weixin.png" style="width: 282px; height: 97px;margin-top:93px" />
          </div>
        </div>
      </div>


			<div  ref="cont_3" style="">
        <!-- magin-top:72px -->
        <div style="height:72px"></div>
        <div style="text-align:center;font-weight: 600;margin:97px 0 52px 0;font-size: 48px;color: rgba(0,0,0,0.88);">专家服务</div>
        <div  class="flex" style="">
          <div style="box-shadow: 1px 13px 32px 1px rgba(0,0,0,0.08);border-radius: 46px;height: 489px;width: 754px;padding: 64px 0 0 60px">
            <div style="font-weight: 600;font-size: 38px;color: rgba(0,0,0,0.88);">职涯罗盘</div>
            <div style="font-size: 19px;color: rgba(0,0,0,0.88);line-height: 36px;width:384px;margin:30px 0 34px 0">
              "职涯罗盘"是一款专为应届大学生量身定制的职涯规划服务。<br>
              帮助每位毕业生都渴望在职场上找到自己的定位和发展方向。
            </div>
            <img src="../assets/images/weixin.png" style="width: 282px; height: 97px" />
          </div>
          <img src="../assets/images/luopan.png" style="width:436px; height: 559px;margin-left:-209px;" />
        </div>
        
        <div  class="flex" style="">
          <img src="../assets/images/duoshou.png" style="width:436px; height: 600px;margin-right:-209px;    z-index: 999;margin-top:68px" />
          <div style="box-shadow: 1px 13px 32px 1px rgba(0,0,0,0.08);border-radius: 46px;width: 754px;padding: 64px 0 0 60px;padding-left:259px;">
            <div style="font-weight: 600;font-size: 38px;color: rgba(0,0,0,0.88);">简历舵手</div>
            <div style="font-size: 19px;color: rgba(0,0,0,0.88);line-height: 36px;width:384px;margin:30px 0 34px 0">
              '简历舵手"是一款专为应届大学生设计的简历优化服务。<br>
              我们的专家团队将根据每位求职者的行业偏好、目标企业和理想岗位，量身定制简历，确保每一份简历都能精准地传达求职者的专业技能和个人特质。
            </div>
            <img src="../assets/images/weixin.png" style="width: 282px; height: 97px;" />
            <div style="height:28px"></div>
          </div>
        </div>

        <div  class="flex" style="">
          <div style="box-shadow: 1px 13px 32px 1px rgba(0,0,0,0.08);border-radius: 46px;height: 489px;width: 754px;padding: 64px 0 0 60px">
            <div style="font-weight: 600;font-size: 38px;color: rgba(0,0,0,0.88);">灯塔之光</div>
            <div style="font-size: 19px;color: rgba(0,0,0,0.88);line-height: 36px;width:384px;margin:30px 0 34px 0">
              "灯塔之光"是一款专为应届大学生设计的求职面试辅导服务。<br>
              "灯塔之光"服务通过邀请目标公司、对应岗位的在职专家，为求职者提供精准、实用的面试模拟和问题回答指导。
            </div>
            <img src="../assets/images/weixin.png" style="width: 282px; height: 97px" />
          </div>
          <img src="../assets/images/dengta.png" style="width:436px; height: 559px;margin-left:-209px;" />
        </div>
      </div>


      <div class="cont_4" ref="cont_4" style="">
        <div style="height:128px"></div>
        <div style="font-size: 48px;color:#000;text-align:center;font-weight: 600;">关于我们</div>
        <div style="height:57px"></div>
        <div class="about">
          <div>
            <div class="textdi">邮箱：1504452775@qq.com</div>
            <div class="textdi" style="margin:32px 0">商务/联系方式：17316127135</div>
            <div class="textdi">客服联系方式：dengtazhiya001</div>
          </div>
          <img src="../assets/images/beian.png" style="width: 336px; height:256px" />
        </div>
        <div style="background: #89AFD0;height: 150px;font-size: 16px;text-align:center;color:#fff;line-height:150px">
          备案号:京ICP备2023021456号-11
        </div>
      </div>
		</div>

	</div>
</template>
<script>
export default {
	data() {
		return {
			tabs: ['首页', '产品服务', '专家服务','关于我们'],
			active: 0,
			cont1: null,
			cont2: null,
			cont3: null,
      cont4: null,
			isClickTab: false,
		}
	},
	methods: {

		switchTab(index) {
      // console.log(index,'index')
			// 根据当前index切换到对应的页面内容
			if (index === 0) {
				this.cont1.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
			} else if (index === 1) {
				this.cont2.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
			}else if (index === 2) {
       
				this.cont3.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
         
			} else {
				this.cont4.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				})
        // console.log('--->',this.cont4)
			}
		},
	},
	mounted() {
		this.cont1 = this.$refs['cont_1']
		this.cont2 = this.$refs['cont_2']
		this.cont3 = this.$refs['cont_3']
    this.cont4 = this.$refs['cont_4']
    
		const tabH = this.$refs['tab'].offsetHeight

    // console.log(tabH,'sssssss')
		// 添加scroll事件监听
		this.$refs['cont'].addEventListener('scroll', () => {
      // console.log('[]][]')
      // console.log(this.cont4.getBoundingClientRect().top,tabH,'this.cont4')
      // console.log(this.cont3.getBoundingClientRect().top,tabH,'this.cont3')
      // console.log(this.cont2.getBoundingClientRect().top,tabH,'this.cont2')
      // console.log(this.cont1.getBoundingClientRect().top,tabH,'this.cont1')
      if (this.cont4.getBoundingClientRect().top <= tabH) {
				this.active = 3
				return false
			}
			if (this.cont3.getBoundingClientRect().top <= tabH) {
				this.active = 2
				return false
			}
			if (this.cont2.getBoundingClientRect().top <= tabH) {
				this.active = 1
				return false
			}
			if (this.cont1.getBoundingClientRect().top <= tabH) {
				this.active = 0
			}
		})
	},
}
</script>
<style  scoped>
.home{
 text-align: center;
}
.flex1{
  display: flex;align-items: center;
}
.flex2{
  display: flex;justify-content: center;
}
.flex{
display: flex;
align-items: center;
justify-content: center;
}
.shouye{
     background: linear-gradient( 180deg, #E2F1FF 0%, #FFFFFF 100%);
}
.text1{
font-weight: 600;
font-size: 36px;
color: #FF662C;
line-height: 63px;
}
.text2{
font-weight: 600;
font-size:47px;
color: rgba(0,0,0,0.88);
line-height: 83px;
}
.box {
	font-size: 28px;
	/* overflow-x: auto; */
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
.tab {
	height: 69px;
	background: rgb(245, 242, 255);
	line-height: 88px;
	color: #666;
	display: -webkit-flex;
	display: flex;
	justify-content: space-around;
  align-items: center;
  font-size: 14px;
}
.active {
	font-size: 14px;
	color: #4E85FF;
}
/* .active::after {
	display: block;
	content: '';
	width: 30px;
	height: 6px;
	margin: auto;
	margin-top: -30px;
	background: #4E85FF;
	border-radius: 3px;
} */
.cont {
	/* height: 300px; */
	flex-grow: 1;
	overflow: auto;

}
.about{
  background: #EEF7FF;
  height: 256px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.textdi{
  font-size: 16px;
color: rgba(0,0,0,0.88);
line-height: 22px;
}

</style>
